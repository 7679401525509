



















































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import { LinkageDeviceEntityModel, LinkageDeviceQueryModel } from '@/entity-model/linkage-device-entity';
import LinkageDeviceService from '@/service/linkage-device';

@Component
export default class LinkageDeviceListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    LinkageDeviceEntityModel = LinkageDeviceEntityModel;
    created() {
        this.initTable({
            service: LinkageDeviceService,
            queryModel: new LinkageDeviceQueryModel(),
            tableColumns: LinkageDeviceEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as LinkageDeviceQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/linkage-device-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/linkage-device-list');
        }
    }
}

